.landing-page {
    background: url('../../assets/login-top-bg.png') center top no-repeat #0099a7;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

h3 {
    color: white;
    margin-bottom: 50px;
}

.temp-footer {
    height: 100px;
    margin: 50px;
}
