.table-box {
    width: 90%;
}

.table-header > h3 {
    padding: 5px 0;
    margin: 0;
    width: 100%;
    background: #79c3c9;
}

.table-header > p {
    width: 100px;
    font-size: 12px;
    color: grey;

}

.data-table {
    border-radius:0 0 5px 5px;
    background:lightgrey;
    max-height: 500px;
    max-width: 100%;
    display: block;
    overflow: scroll;
}

.table-header {
    width: 100%;
    background: #79c3c9;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-header > h3 {
    padding:0 10px;
}

.row-name-box {
    position: sticky;
}


.row-names {
    border: 1px solid grey;
    color: grey;
    background: white;
    font-size: 18px;
    padding: 0 5px;
    text-align: center;
    white-space: nowrap;
}

.row-names > span {
    cursor: pointer;
}

.table-data {
    background: white;
    font-size: 12px; 
    padding: 0 5px;
}

.table-body{
    transition: ease-in-out .25s;
}

.table-body-closed{
    transition: ease-in-out .25s;
    max-height: 50px;
}


.bold {
    font-weight: bold;
}

.header-button {
    text-decoration: none;
    outline: 0;
    border: 0;
    border: none;
    background: none;
    margin: 0 10px;
    padding: 0;
    font-size: 25px;
    text-align: center;
    
}

.header-button:hover{
    text-decoration: none;
    outline: 0;
    border: 0;
    color: white;
}

.table-header > p {
    color: black;
}

.arrow {
    opacity: 0;
    width: 10px;
}

.arrow:hover {
    transition: ease-in-out .25s;
    opacity: 1;
}

.data-table-box {
    width: 100%;
}

.large {
    width: 60%;
}