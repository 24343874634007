.login-box {
    color: white;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.login-input-box {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 12px;
    padding-right: 20px;
}
 .title {
     width: 50%;
     text-align: right;
     padding: 15px;
 }

 .input { 
     height: 20px;
     font-size: 14px;
     padding: 6px 12px;
     border: 1px solid #ccc;
     border-radius: 4px;
     outline: none;
 }

 .input:focus {
     border-color: #66afe9;
     box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
 }

 .submit { 
    background: white;
    margin-right: 20px;
    padding: 7px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
 }

 .submit:hover {
    background: #e6e6e6;
 }

