.content-container{
    margin: 0 auto;
    width: 85%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
}

.accounts-box {
    background: white;
    width: 90%;
    margin: 30px auto 0 auto;
    min-height: 60vh;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(106, 106, 106, 0.25);
}
.heading{ 
    width: 100%;
    padding: 5px 0;
    border-radius: 5px 5px 0 0;
    margin: 0;
    color: white;
    background: #79c3c9;
}

.empty-box {
    color: grey;
    margin: 100px auto;
}

.table-btn {
    align-self: flex-end;
    margin-right: 5%;
}