.header{
    width: 100%;
    height: 80px;
    position: fixed;
    background: #0099A7;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    margin-top: 5px;
    margin-left: 20px;
}

.logo-text {
    padding: 0;
    margin: 0;
    color: white;
    font-weight: 700;

}

.logout {
    margin-right: 20px;
    padding: 5px;
    border: none;
    color: white;
    background: none;
    font-weight: 700;
    outline: none;
}

.logout:active, .logout:hover {
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
