.get-form {
    box-sizing: border-box;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;   
}

.get-form > h2 {
    color: #686868;
    text-align: left;
    width: 100%;
    padding: 0 0 0 30px;
    margin: 10px 0;
}

/* tab bar classes */

.tab-bar {
    width: 100%;
    display: flex;
}

.tab-box {
    color: white;
    background: #79c3c9;
    border-bottom: 1px solid white;
    padding: 5px 10px;
    margin: 0 4px;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    font-weight: 500;
}

.tab-selected {
    color: grey;
    font-weight: 700;
    background-color: rgb(235, 235, 235);
    border: 0;
}

.input-section {
    box-sizing: border-box;
    padding:15px 5px;
    background: rgb(235, 235, 235);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.input-box {
    display: flex;
    flex-direction: column;
    width: 33%;
    border-right: 1px solid lightgrey;
}

.input {
    box-sizing: border-box;
    width: 90%;
    height: 30px;
    margin: 5px;
}

.input-box > button {
    width: 45%;
    margin: 2px;
}

.button-box {
    width: 62%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.input-box > label {
    text-align: left;
    margin-left: 15px;
}

.no-qual-box {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
}

.no-qual-box > h3 {
    color: grey;
    width: 100%;
    height: 20px;
    margin: 5px 0;
    text-align: left;
}

.range {
    vertical-align: middle;
}


.id-list {
    box-sizing: border-box;
    background: rgb(235, 235, 235);
    align-self: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 30px;
    max-height: 230px;
    border-radius: 0 0 5px 5px;
    padding: 0 0 15px 15px;
}

.id-list > div {
    width: 18%;
    height :20px;
    border-bottom: 1px solid grey;
    margin: 0 3px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.id-list > div:hover {
    background: lightgrey;
}

.id-list > p {
    padding: 0;
}

.delete-button::selection {
    outline: none;
    background: none;
    cursor: pointer;
}

.delete-button:hover {
    cursor: pointer;
    color: tomato;
    font-weight: bolder;
}

.recycle-form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.recycle-form > .input {
    width: 30%;
}

.recycle-form > label {
    width: 100%;
    text-align: left;
    margin-left: 15px;
}

.list-box {
    box-sizing: border-box;
    width: 100%;
    min-height: 20px;
    display: flex;
    border-radius:  0 0 5px 5px;
}

.sub-list {
    padding: 5px 0;
    box-sizing: border-box;
    min-width: 50%;
    background: rgb(235, 235, 235);
    min-height: 30px;
    max-height: 330px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.list-item {
    min-width: 32%;
    height :20px;
    border-bottom: 1px solid grey;
    margin: 0 3px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-item:hover {
    background-color: lightgrey;
}

.recycle-button-box {
    width: 100%;
display:flex;
align-items: flex-start;
}

.box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.box > .button-box {
    width: 100%;
}

.form-box {
    width: 60%;
}

.edit-list {
    margin: 5px 0;
    width: 85%;
    min-width: 130px;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    max-height: 300px;
    min-height: 0px;
    overflow-y: auto;
    background: white;
}
.edit-list > p {
    padding: 3px 20px;
    text-align: left;
    margin: 0;
}

.edit-list > div {
    min-width: 30%;
    height :20px;
    border-bottom: 1px solid grey;
    margin: 0 3px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-list > div:hover {
    background-color: lightgrey;
}

.modal {
    width: 400px;
    height: 200px;
    background-color: white;
    margin: 200px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal > p {
    padding: 10px;
}

.loading-modal {
    outline: none;
    margin: 40vh auto;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.loading-modal > h2 {
    text-align: center;
}

.spinner {
    background: white;
    border-radius: 50%;
}