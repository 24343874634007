body {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 5px 0;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button:hover,
.button:focus {
  text-decoration: none;
}

.button:focus,
.button.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}


.button:not(:disabled):not(.disabled):active {
  background-image: none;
}

.blue-btn {
  color: white;
  background-color: #0099A7;
  margin: 5px;
}

.lightblue-btn {
  color: white;
  background-color: #79c3c9;
  margin: 5px;
}

.green-btn {
  color: white;
  background-color: rgb(106, 209, 106);
  margin: 5px;
}

.red-btn {
  color: white;
  background-color: tomato;
  margin: 5px;
}

.darkblue-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 112, 123, 0.25);
}

.blue-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 167, 0.25);
}

.lightblue-btn:focus {
  outline: 0;
  box-shadow:  0 0 0 0.2rem #79c2c925;
}

.blue-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 233, 253, 0.25);
}


.green-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 255, 64, 0.25);
}

.red-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 21, 21, 0.25);
}

.paper {
  background: white;
  border-radius: 5px;
  margin: 20px auto;
  box-shadow: 5px 5px 5px rgba(106, 106, 106, 25%)
}

.error-message {
  border: 1px solid red;
  width: 100%;
  background: rgba(255, 108, 83, 0.5);
  padding: 5px 0;cursor: pointer;
}

.good-message {
  border: 1px solid rgb(91, 248, 144);
  width: 100%;
  background: rgba(136, 255, 113, 0.5);
  padding: 5px 0;cursor: pointer;
}